import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { IUserFacade, USER_FACADE } from '~/core/features'
import { ADMIN_ROLE } from '~const/index'

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    @Inject(USER_FACADE) private userFacade: IUserFacade,
    @Inject(PLATFORM_ID) private platformID: Object,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.userFacade.user$.pipe(
      // skipWhile(user => user === undefined),
      map(user => {
        if (user?.role?.name !== ADMIN_ROLE) {
          isPlatformBrowser(this.platformID) && this.router.navigateByUrl('/')
          return false
        }
        return true
      })
    )
  }
}
