<app-popup class="popup">
  <ng-container title>
    <h3 class="title">{{ 'popup.guest.title' | translate }}</h3>
  </ng-container>

  <ng-container content>
    <span class="content">
      {{ 'popup.guest.message' | translate }}
    </span>
  </ng-container>

  <ng-container actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
      {{ 'popup.guest.okBtnTitle' | translate }}
    </button>
  </ng-container>
</app-popup>
