import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

import { AuthService } from '@auth0/auth0-angular'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    @Optional() private authService: AuthService | null,
    @Inject(PLATFORM_ID) private platformID: Object,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return (this.authService?.user$ || of(null)).pipe(
      map(user => {
        if (!user) {
          isPlatformBrowser(this.platformID) && this.router.navigateByUrl('/')
          return false
        }
        return true
      })
    )
  }
}
