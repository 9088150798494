import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'

import { QUARTERLY_DASHBOARD_FACADE } from '~/core/features'
import { MetaTagService } from '~/core/services'

export const quarterlyDashboardQuarterDataResolver: ResolveFn<void> = () => {
  inject(MetaTagService).setQuarterlyDashboardPageMeta()
  return inject(QUARTERLY_DASHBOARD_FACADE).getQuarterlyDashboardQuarterData()
}
