import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router'

import { COMPANY_FACADE } from '~/core/features'

export const companyForecastResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
  const ticker = route.paramMap.get('ticker')
  ticker && inject(COMPANY_FACADE).getForecast(ticker)
  ticker && inject(COMPANY_FACADE).showCancelOption(true)

  return
}
