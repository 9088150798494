import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'

import { AGGREGATION_ANALYSIS_FACADE } from '~/core/features'
import { MetaTagService } from '~/core/services'

export const aggregationAnalysisQuarterDataResolver: ResolveFn<void> = () => {
  inject(MetaTagService).setQuarterlyDashboardPageMeta()
  return inject(AGGREGATION_ANALYSIS_FACADE).getAggregationAnalysisQuarterData()
}
