<div class="app-wrapper mat-app-background">
  <div class="app-content-wrapper">
    <app-header class="app-header"></app-header>
    <app-progress-bar></app-progress-bar>

    <div class="content-scroll-block">
      <main class="app-main">
        <router-outlet></router-outlet>
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</div>
