import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'

import { PERFORMANCE_FACADE } from '~/core/features'
import { MetaTagService } from '~/core/services'

export const performanceQuarterListResolver: ResolveFn<void> = () => {
  inject(MetaTagService).setPerformancePageMeta()
  return inject(PERFORMANCE_FACADE).getPerformanceQuarterList()
}
