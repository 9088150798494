import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'

import { TranslateModule } from '@ngx-translate/core'

import { GuestPopupComponent } from './guest-popup.component'
import { GuestPopupService } from './guest-popup.service'

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    GuestPopupComponent,
  ],
  providers: [GuestPopupService],
})
export class GuestPopupModule {}
