import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { Observable } from 'rxjs'

import { GuestPopupComponent } from './guest-popup.component'

@Injectable()
export class GuestPopupService {
  constructor(public dialog: MatDialog) {}

  openDialog(): Observable<boolean | undefined> {
    return this.dialog.open(GuestPopupComponent, { width: '500px' }).afterClosed()
  }
}
